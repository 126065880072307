<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import isEqual from 'lodash-es/isEqual'
import { customAlphabet } from 'nanoid'
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import Analytics from '/~/core/analytics'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import { sleep } from '/~/utils/timer'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerMethodTile from '/~/components/drawer/components/tiles/drawer-method-tile.vue'
import { useCms } from '/~/composables/cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

export default {
  name: 'drawer-card-form',
  components: {
    DrawerMethodTile,
    BaseAction,
    BaseButton,
    BaseLoader,
    BaseCheckbox,
  },
  setup(_, { emit }) {
    const router = useRouter()
    const { isDarkThemeForEwallet } = useCms()
    const {
      creating,
      formLoading,
      formReady,
      creditCardFormData,
      createCreditCardForm,
      isMethodAvailable,
    } = usePaymentMethods()

    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 16)
    const containerId = ref()

    const tocAccepted = ref(false)
    const form = ref(null)

    const isOneCardEnabled = computed(() => {
      return isMethodAvailable(PaymentMethodType.creditCard, 'oneCardLimit')
    })

    const isDisabled = computed(
      () =>
        creating.value ||
        formLoading.value ||
        !tocAccepted.value ||
        !cardPresentation.value.isValid ||
        !cardPresentation.value.isReadyToSubmit
    )

    watch(formReady, (value) => {
      if (value && !tocAccepted.value) {
        form.value?.disableSubmit()
      }
    })

    watch(creditCardFormData, (newVal, oldVal) => {
      if (Analytics.initiated) {
        onCardFormChange(newVal, oldVal)
      }
    })

    watch(isDarkThemeForEwallet, (value) => {
      form.value?.changeTheme(value ? 'dark' : 'light')
    })

    onMounted(async () => {
      await creditFormInit()
    })

    async function creditFormInit() {
      containerId.value = nanoid()
      await sleep(0) // wait for containerId to be updated in DOM
      form.value = await createCreditCardForm({
        containerId: `#${containerId.value}`,
      })
    }

    onBeforeUnmount(() => {
      form.value?.dispose()
      emitter.off('payment-methods:created', onComplete)
    })

    function onCancelForm() {
      router.back()
    }

    async function onSubmitForm() {
      if (formLoading.value || isDisabled.value) {
        return
      }

      try {
        emitter.on('payment-methods:created', onComplete)
        form.value.submit()
      } catch (error) {
        emitter.off('payment-methods:created', onComplete)
        console.error(error)
      }
    }

    const cardPresentation = computed(() => {
      const { data, isValid, isReadyToSubmit } = creditCardFormData.value
      const { cardholder, number, expiryMonth, expiryYear } = data ?? {}

      return {
        type: PaymentMethodType.creditCard,
        brand: getCardTypeByNumber(number)?.type,
        number: number || '',
        name: cardholder,
        expiry: {
          month:
            expiryMonth < 10
              ? `0${expiryMonth}`
              : expiryMonth?.toString() || '',
          year: expiryYear?.toString() || '',
        },
        isValid,
        isReadyToSubmit,
      }
    })

    function onComplete(newCard) {
      emitter.off('payment-methods:created', onComplete)
      emit('submit', { id: newCard.id, type: PaymentMethodType.creditCard })
    }

    function toggleTocCheckbox(isActivated) {
      if (isActivated) {
        form.value.enableSubmit()
      } else {
        form.value.disableSubmit()
      }
    }

    function onCardFormChange(newVal, oldVal) {
      const labels = {
        number: 'Card Number',
        cvc: 'CVC',
        expiryMonth: 'Expiry Date',
        expiryYear: 'Expiry Date',
        cardholder: 'Cardholder Name',
      }

      // Ignore form value change upon initialization
      if (
        Object.keys(oldVal.data || {}).length === 0 ||
        isEqual(newVal.data, oldVal.data)
      )
        return

      if (newVal && 'data' in newVal) {
        Object.keys(newVal.data).forEach((key) => {
          if (newVal.data[key] !== oldVal.data[key]) {
            Analytics.sendEvent({
              pageGroup: 'Drawer',
              page: 'Add payment method',
              component1: 'Credit/Debit card',
              label: `${labels[key]} field changed`,
            })
          }
        })
      }
    }

    function showTermsAndConditions() {
      bottomSheet.show('terms-conditions', { to: 'menu-modal' })
    }

    return {
      isDarkThemeForEwallet,
      containerId,
      isOneCardEnabled,
      isDisabled,
      creating,
      onCancelForm,
      onSubmitForm,
      formLoading,
      cardPresentation,
      tocAccepted,
      toggleTocCheckbox,
      showTermsAndConditions,
    }
  },
}
</script>

<template>
  <div
    class="relative flex grow flex-col items-center justify-between"
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
  >
    <base-loader v-if="formLoading" class="py-5" fullwidth />
    <drawer-method-tile v-else class="mb-10" :method="cardPresentation" />
    <div class="mb-14 w-full flex-auto px-5" @keyup.enter="onSubmitForm">
      <div
        :id="containerId"
        :class="{
          'pointer-events-none opacity-50': creating,
          absolute: formLoading, // prevent scroll while loading
        }"
      />
    </div>

    <div v-if="isOneCardEnabled" class="mb-5 text-neutral-400">
      * Please note that your card may only be linked to a single profile.
    </div>
    <div
      class="-mx-5 mt-auto w-full border-t border-eonx-neutral-200 bg-white px-5 pb-5"
    >
      <div class="grid w-full grid-cols-2 gap-x-5">
        <div class="col-span-2 py-5">
          <base-checkbox
            v-model="tocAccepted"
            v-analytics:input="
              (e) => ({
                pageGroup: 'Drawer',
                page: 'Add payment method',
                component1: 'Credit/Debit card',
                label: `${e.target.checked ? 'Tick' : 'Untick'} Agree to T&C`,
              })
            "
            :disabled="creating"
            look="v2"
            @change="toggleTocCheckbox"
          >
            <span class="font-normal text-default">
              I agree to
              <base-action
                v-analytics:click="{
                  pageGroup: 'Drawer',
                  page: 'Add payment method',
                  component1: 'Credit/Debit card',
                  cta: 'Terms & Conditions',
                }"
                class="font-semibold text-primary underline"
                type="link"
                :click-only="true"
                @click="showTermsAndConditions"
              >
                Terms & Conditions
              </base-action>
            </span>
          </base-checkbox>
        </div>
        <base-button
          v-analytics:click="{
            pageGroup: 'Drawer',
            page: 'Add payment method',
            component1: 'Credit/Debit card',
            cta: 'Cancel',
          }"
          class="w-full"
          look="outlined-color"
          @click="onCancelForm"
        >
          Cancel
        </base-button>
        <base-button
          v-analytics:click="{
            pageGroup: 'Drawer',
            page: 'Add payment method',
            component1: 'Credit/Debit card',
            cta: 'Confirm',
          }"
          class="w-full"
          type="primary"
          :disabled="isDisabled"
          @click="onSubmitForm"
        >
          {{ creating ? 'Confirming...' : 'Confirm' }}
        </base-button>
      </div>
    </div>
  </div>
</template>
